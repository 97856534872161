<template>
    <div>
        Zoho contact sync
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>